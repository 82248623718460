<template>
    <div :class="$style.plan_usage">
        <page-header :class="$style.header" :hasBorder="true" size="full" :title="$t('plan.page_title')"></page-header>
        <div class="x-wrap x-wrap--full">
            <div :class="$style.container">
                <div :class="$style.current">
                    <div :class="$style.header">
                        <div :class="$style.title">{{ $t('plan.current_plan') }}</div>
                        <div :class="$style.plan_name">{{ packageName }}</div>
                        <div :class="$style.plan_due">{{ nextBillingTips }}</div>
                    </div>

                    <div :class="$style.content">
                        <div :class="$style.image"></div>
                        <div :class="$style.desc">{{ $t('plan.plan_desc') }}</div>
                        <pt-button size="medium" @click="contactUs">{{ $t('plan.change_plan') }}</pt-button>
                    </div>
                </div>
                <div :class="$style.usage" v-if="packageInfo">
                    <div :class="$style.progress">
                        <div :class="$style.mau_percent" v-if="packageInfo.isPtxPlan">
                            <span
                                :class="[
                                    $style.used,

                                    currentPrivilege.monthlyActiveUsers !== -1 &&
                                        currentUsage.monthlyActiveUsers >= currentPrivilege.monthlyActiveUsers &&
                                        $style.over_used
                                ]"
                                >{{ currentUsage.monthlyActiveUsers.toLocaleString() }}</span
                            >
                            /{{
                                currentPrivilege.monthlyActiveUsers === -1
                                    ? $t('plan.unlimited')
                                    : currentPrivilege.monthlyActiveUsers.toLocaleString()
                            }}
                        </div>
                        <div :class="$style.mau_usage" v-if="currentUsage && currentPrivilege && packageInfo.isPtxPlan">
                            <div :class="$style.title">{{ $t('plan.mau') }}</div>
                            <div :class="$style.reset_date">{{ $t('plan.mau_reset_date', { date: resetDate }) }}</div>
                        </div>
                        <div
                            :class="[
                                $style.mau_progress,
                                mauPercent > 80 && mauPercent < 100 && $style.mau_warn,
                                mauPercent >= 100 && $style.mau_danger
                            ]"
                            v-if="packageInfo.isPtxPlan"
                        >
                            <div
                                :class="$style.mau_used"
                                :style="{
                                    width: mauPercent + '%'
                                }"
                            ></div>
                        </div>
                        <div :class="$style.setting_usage">
                            <div :class="$style.setting_usage_item" v-for="item in usageOptions" :key="item.key">
                                <div :class="$style.item_percent">
                                    <span
                                        :class="[
                                            $style.used,
                                            currentPrivilege[item.key] !== -1 &&
                                                currentUsage[item.key] >= currentPrivilege[item.key] &&
                                                $style.over_used
                                        ]"
                                        >{{ currentUsage[item.key] || 0 }}</span
                                    >
                                    /{{
                                        currentPrivilege[item.key] === -1
                                            ? $t('plan.unlimited')
                                            : currentPrivilege[item.key]
                                    }}
                                </div>
                                <div :class="$style.item_name">{{ item.name }}</div>
                            </div>
                        </div>
                    </div>
                    <div :class="$style.feature">
                        <div
                            v-for="item in featureList"
                            :key="item.key"
                            :class="[$style.feature_item, item.active && $style.active]"
                        >
                            <div :class="$style.dot"></div>
                            {{ $t(item.text) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PageHeader from '@/common/packages/page-header/PageHeader';
import profileApis from '@/apis/profile.apis';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import intercomSerice from '@/common/services/intercom.service';
import PermissionConfig from '@/common/configs/permissions.config';
import permissionUtils from '@/common/utils/permission';
import features from './features.config';
import dayjs from 'dayjs';
export default {
    name: 'PlanUsage',
    data() {
        const usageOptions = [
            {
                key: 'campaigns',
                name: this.$t('plan.campaign_usage'),
                used: 1,
                total: 3
            },
            {
                key: 'userGroups',
                name: this.$t('menu.users'),
                used: 3,
                total: 3
            },
            {
                key: 'goals',
                name: this.$t('menu.goals'),
                used: 2,
                total: 3
            }
        ];
        return {
            usageOptions
        };
    },

    mounted() {
        this.getProfileInfo(this.profileInfo.sid);
    },

    computed: {
        ...mapGetters('main', ['profileInfo', 'packageInfo', 'userInfo']),

        currentPrivilege() {
            return this.packageInfo.privilege;
        },
        currentUsage() {
            return this.packageInfo.usage;
        },
        currentPackConf() {
            return this.packageInfo.packageBaseConf;
        },
        currentPackageType() {
            return this.currentPackConf.packageType;
        },
        packageName() {
            const lang = this.userInfo.i18n?.toLowerCase();
            const packageNameObj = JSON.parse(this.currentPackConf?.packageName);
            return packageNameObj[lang];
        },
        mauPercent() {
            const percent = (this.currentUsage?.monthlyActiveUsers / this.currentPrivilege?.monthlyActiveUsers) * 100;
            return Math.min(percent, 100) >= 0 ? Math.min(percent, 100) : 5;
        },
        resetDate() {
            const date = dayjs(this.packageInfo?.startTime).date();
            return dayjs(this.packageInfo?.startTime)
                .year(dayjs().year())
                .month(dayjs().month())
                .add(Number(date <= dayjs().date()), 'month')
                .format('YYYY/MM/DD');
        },
        expireDate() {
            return dayjs(this.packageInfo?.endTime).format('YYYY/MM/DD');
        },
        nextBillingTips() {
            switch (this.currentPackageType) {
                case 0:
                    return '';
                case 1:
                    return this.$t('plan.expire_date_trial', {
                        name: this.packageName,
                        date: dayjs(this.packageInfo?.endTime).format('YYYY/MM/DD')
                    });
                case 2:
                    return this.$t('plan.expire_date_paid', {
                        name: this.packageName,
                        date: dayjs(this.packageInfo?.endTime)
                            .add(1, 'day')
                            .format('YYYY/MM/DD')
                    });
            }
            return '';
        },

        featureList() {
            let { commonFeature, advanceFeature } = features;
            advanceFeature.map(item => {
                if (['useAI', 'dataimport'].includes(item.key)) {
                    item.active = permissionUtils.includePermissionWithStore([PermissionConfig.engageVariant]);
                } else {
                    item.active = !!this.currentPrivilege[item.key];
                }
                return item;
            });
            return commonFeature.concat(advanceFeature.sort((a, b) => b.active - a.active));
        }
    },
    methods: {
        ...mapMutations('users', ['UPDATE_USER_PROPERTIES']),
        ...mapMutations('main', ['UPDATE_PACKAGE_INFO', 'UPDATE_PROFILE_INFO']),

        getProfileInfo(sid) {
            return Promise.all([
                profileApis.getProfileInfo(null, { where: { sid }, aid: this.userInfo.id }),
                profileApis.getProfilePackage(null, { profileId: sid })
            ])
                .then(res => {
                    const profile = { ...res[0], endTime: res[1]?.endTime };

                    this.UPDATE_PROFILE_INFO(profile);
                    this.UPDATE_PACKAGE_INFO(res[1]);

                    return Promise.resolve(profile);
                })
                .catch(() => Promise.reject(new Error('get profile info failed.')));
        },

        contactUs() {
            intercomSerice?.contact();
        }
    },
    components: {
        PageHeader
    }
};
</script>
<style lang="scss" module>
@import '@/styles/import.scss';
.plan_usage {
    height: 100%;
    .container {
        padding-bottom: 120px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 52px;
        grid-template-columns: repeat(14, 1fr);

        .current {
            background: $pt-white;
            grid-column-start: span 4;
            padding: 40px 40px 52px 40px;
            border-radius: 2px;
            border: 1px solid $pt-black-40;

            .header {
                .title {
                    color: $pt-black-600;
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 20px;
                }
                .plan_name {
                    color: $pt-green-60;
                    font-size: 36px;
                    line-height: 54px;
                    font-weight: bold;
                }
                .plan_due {
                    color: $pt-black-600;
                    font-size: 12px;
                    line-height: 17px;
                    //   width: 190px;
                    margin-bottom: 18px;
                }
            }
            .content {
                display: flex;
                flex-direction: column;
                align-items: center;
                .image {
                    width: 260px;
                    height: 160px;
                    background: url('~@assets/images/plan/plan_usage.png') no-repeat;
                    background-size: contain;
                }
                .desc {
                    text-align: center;
                    width: 190px;
                    color: $pt-black-600;
                    line-height: 17px;
                    font-size: 12px;
                    margin: 12px 0 42px;
                }
            }
        }

        .usage {
            height: 100%;
            grid-column-start: span 10;
            background: $pt-white;
            padding: 40px 40px 58px;
            border: 1px solid $pt-black-40;
            border-radius: 2px;

            .progress {
                padding-bottom: 40px;
                border-bottom: 1px solid $pt-black-40;

                font-size: 14px;
                .title {
                    width: max-content;
                    color: $pt-black-600;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 19px;
                }
                .mau_progress {
                    width: 100%;
                    border: 1px solid $pt-success;
                    border-radius: 2px;
                    height: 20px;
                    &.mau_warn {
                        border: 1px solid $pt-renubd;
                        .mau_used {
                            background: $pt-renubd;
                        }
                    }
                    &.mau_danger {
                        border: 1px solid $pt-danger;
                        .mau_used {
                            background: $pt-danger;
                        }
                    }
                    .mau_used {
                        background: $pt-success;
                        height: 100%;
                    }
                }
                .mau_usage {
                    display: flex;
                    align-items: baseline;
                    color: $pt-black-600;
                    margin-bottom: 20px;
                    .title {
                        flex: 1;
                    }
                    .reset_date {
                        width: max-content;
                        font-size: 14px;
                        line-height: 19px;
                        text-align: right;
                    }
                }
                .mau_progress + .setting_usage {
                    margin-top: 40px;
                }
                .setting_usage {
                    display: flex;
                    .setting_usage_item {
                        margin-right: 24px;
                        color: $pt-black-600;
                        font-size: 14px;
                        width: 140px;
                        .item_name {
                            font-weight: bold;
                        }
                    }
                }
            }
            .feature {
                display: flex;
                flex-flow: wrap;
                margin-top: 38px;
                .feature_item {
                    width: 50%;
                    color: $pt-black-50;
                    font-size: 13px;
                    line-height: 18px;
                    margin-bottom: 12px;
                    &.active {
                        color: $pt-black-600;
                    }
                    .dot {
                        display: inline-block;
                        width: 4px;
                        height: 4px;
                        line-height: 28px;
                        margin-right: 8px;
                        margin-bottom: 3px;
                        border-radius: 50%;
                        background-color: $pt-green-60;
                    }
                }
            }
            .used {
                color: $pt-black-600;
                font-size: 24px;
                &.over_used {
                    color: $pt-danger;
                }
            }
        }
    }
}
</style>
