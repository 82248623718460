const commonFeature = [
    /*  {
        key: 'editor',
        text: 'plan.editor',
        active: true
    }, */
    {
        key: 'popup',
        text: 'plan.popup',
        active: true
    },
    {
        key: 'inline',
        text: 'plan.inline',
        active: true
    },
    {
        key: 'userfilter',
        text: 'plan.user_filter',
        active: true
    }
];
const advanceFeature = [
    {
        key: 'useABTest',
        text: 'plan.abtest',
        active: true
    },
    {
        key: 'useCodeMode',
        text: 'plan.codemode',
        active: true
    },
    {
        key: 'useCSVDownload',
        text: 'plan.csv_export',
        active: true
    },
    {
        key: 'useAI',
        text: 'plan.smart_traffic',
        active: true
    },
    {
        key: 'dataimport',
        text: 'plan.data_import',
        active: true
    }
];
export default {
    commonFeature,
    advanceFeature
};
