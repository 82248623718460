var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.plan_usage},[_c('page-header',{class:_vm.$style.header,attrs:{"hasBorder":true,"size":"full","title":_vm.$t('plan.page_title')}}),_c('div',{staticClass:"x-wrap x-wrap--full"},[_c('div',{class:_vm.$style.container},[_c('div',{class:_vm.$style.current},[_c('div',{class:_vm.$style.header},[_c('div',{class:_vm.$style.title},[_vm._v(_vm._s(_vm.$t('plan.current_plan')))]),_c('div',{class:_vm.$style.plan_name},[_vm._v(_vm._s(_vm.packageName))]),_c('div',{class:_vm.$style.plan_due},[_vm._v(_vm._s(_vm.nextBillingTips))])]),_c('div',{class:_vm.$style.content},[_c('div',{class:_vm.$style.image}),_c('div',{class:_vm.$style.desc},[_vm._v(_vm._s(_vm.$t('plan.plan_desc')))]),_c('pt-button',{attrs:{"size":"medium"},on:{"click":_vm.contactUs}},[_vm._v(_vm._s(_vm.$t('plan.change_plan')))])],1)]),(_vm.packageInfo)?_c('div',{class:_vm.$style.usage},[_c('div',{class:_vm.$style.progress},[(_vm.packageInfo.isPtxPlan)?_c('div',{class:_vm.$style.mau_percent},[_c('span',{class:[
                                _vm.$style.used,

                                _vm.currentPrivilege.monthlyActiveUsers !== -1 &&
                                    _vm.currentUsage.monthlyActiveUsers >= _vm.currentPrivilege.monthlyActiveUsers &&
                                    _vm.$style.over_used
                            ]},[_vm._v(_vm._s(_vm.currentUsage.monthlyActiveUsers.toLocaleString()))]),_vm._v(" /"+_vm._s(_vm.currentPrivilege.monthlyActiveUsers === -1 ? _vm.$t('plan.unlimited') : _vm.currentPrivilege.monthlyActiveUsers.toLocaleString())+" ")]):_vm._e(),(_vm.currentUsage && _vm.currentPrivilege && _vm.packageInfo.isPtxPlan)?_c('div',{class:_vm.$style.mau_usage},[_c('div',{class:_vm.$style.title},[_vm._v(_vm._s(_vm.$t('plan.mau')))]),_c('div',{class:_vm.$style.reset_date},[_vm._v(_vm._s(_vm.$t('plan.mau_reset_date', { date: _vm.resetDate })))])]):_vm._e(),(_vm.packageInfo.isPtxPlan)?_c('div',{class:[
                            _vm.$style.mau_progress,
                            _vm.mauPercent > 80 && _vm.mauPercent < 100 && _vm.$style.mau_warn,
                            _vm.mauPercent >= 100 && _vm.$style.mau_danger
                        ]},[_c('div',{class:_vm.$style.mau_used,style:({
                                width: _vm.mauPercent + '%'
                            })})]):_vm._e(),_c('div',{class:_vm.$style.setting_usage},_vm._l((_vm.usageOptions),function(item){return _c('div',{key:item.key,class:_vm.$style.setting_usage_item},[_c('div',{class:_vm.$style.item_percent},[_c('span',{class:[
                                        _vm.$style.used,
                                        _vm.currentPrivilege[item.key] !== -1 &&
                                            _vm.currentUsage[item.key] >= _vm.currentPrivilege[item.key] &&
                                            _vm.$style.over_used
                                    ]},[_vm._v(_vm._s(_vm.currentUsage[item.key] || 0))]),_vm._v(" /"+_vm._s(_vm.currentPrivilege[item.key] === -1 ? _vm.$t('plan.unlimited') : _vm.currentPrivilege[item.key])+" ")]),_c('div',{class:_vm.$style.item_name},[_vm._v(_vm._s(item.name))])])}),0)]),_c('div',{class:_vm.$style.feature},_vm._l((_vm.featureList),function(item){return _c('div',{key:item.key,class:[_vm.$style.feature_item, item.active && _vm.$style.active]},[_c('div',{class:_vm.$style.dot}),_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])}),0)]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }